import { useForm, useDispatch, TextField, Modal } from "common";
import { closeModal } from "actions";
import { isValidEmail } from "utils";
import { INVALID_EMAIL_ERROR } from "../Login";

const formKeys = {
  reference: "reference",
};

const useFormInputs = () => {
  const reference = {
    key: formKeys.reference,
    value: "",
    label: "Email",
    type: "email",
    required: false,
    noWhiteSpace: true,
  };

  return [reference];
};

export const ReferenceModal = ({
  dispatchCreateUser,
  userEmail,
}: {
  dispatchCreateUser: (referencedBy?: string) => void;
  userEmail: string;
}) => {
  const dispatch = useDispatch();
  const formInputs = useFormInputs();
  const form = useForm(formInputs);
  const { updateValues } = form;

  const dispatchRegistrationRequest = () => {
    const valuesObj = form.getValuesObj();
    const referencedBy = valuesObj[formKeys.reference];

    if (referencedBy) {
      if (!isValidEmail(referencedBy)) {
        updateValues([{ key: formKeys.reference, error: INVALID_EMAIL_ERROR }]);
        return;
      }

      if (userEmail === referencedBy) {
        updateValues([
          {
            key: formKeys.reference,
            error: "No puedes usar tu mismo email como referencia.",
          },
        ]);
        return;
      }
    }

    dispatchCreateUser(referencedBy);

    dispatch(closeModal());
  };

  return (
    <Modal
      title="¿Te recomendó un conocido?"
      cancelButton={{ hide: true }}
      submitButton={{ text: "Continuar", onClick: dispatchRegistrationRequest }}
    >
      <p>
        Si te recomendó algún conocido tuyo, puedes decirnos quién fue y le{" "}
        <strong>regalamos $500 MXN</strong> en créditos cuando publiques tu
        primer anuncio.
      </p>
      <p>
        Tú también puedes recomendar a conocidos tuyos para que se anuncien con
        nosotros y te regalamos $500 MXN en créditos cuando publiquen su primer
        anuncio.
      </p>
      <p>Si nadie te recomendó, deja el campo vacío y da click en continuar.</p>
      <TextField form={form} formKey={formKeys.reference} />
    </Modal>
  );
};
