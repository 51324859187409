import { FormInput } from "common";

export const formKeys = {
  phoneNumber: "phoneNumber",
  email: "email",
  smsConsent: "smsConsent",
  password: "password",
  repeatPassword: "repeatPassword",
  code: "code",
};

export const useFormInputs = (): Array<FormInput> => {
  const phoneNumber = {
    key: formKeys.phoneNumber,
    value: "",
    label: "Teléfono móvil",
    placeholder: "10 dígitos",
    type: "tel",
    required: true,
    noWhiteSpace: true,
  };

  const email = {
    key: formKeys.email,
    value: "",
    label: "Email",
    placeholder: "Email",
    type: "email",
    required: true,
    noWhiteSpace: true,
    forceLowerCase: true,
  };

  const smsConsent = {
    key: formKeys.smsConsent,
    value: false,
    required: true,
    label: "Acepto recibir notificaciones vía email, SMS y WhatsApp",
  };

  const password = {
    key: formKeys.password,
    value: "",
    label: "Contraseña",
    type: "password",
    required: true,
  };

  const repeatPassword = {
    key: formKeys.repeatPassword,
    value: "",
    label: "Repite tu contraseña",
    type: "password",
    required: true,
  };

  const code = {
    key: formKeys.code,
    value: "",
    label: "Código",
    required: true,
    noWhiteSpace: true,
  };

  return [phoneNumber, email, smsConsent, password, repeatPassword, code];
};
