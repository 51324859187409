// lodash
import size from "lodash/size";
import isEmpty from "lodash/isEmpty";
// Material Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useState } from "react";
import styled from "@emotion/styled";
import Link from "next/link";
import {
  Spacer,
  Button,
  Checkbox,
  TextField,
  useDispatch,
  useCountry,
  useForm,
  useSelector,
  InputError,
  REQUIRED_FIELD_ERROR,
  handleNewPhoneNumberError,
} from "common";
import { NEXT_PUBLIC_ADMIN_WHATSAPP } from "envVars";
import { openModal, openSnackbar } from "actions";
import {
  registrationStartRequest,
  registrationVerifyPhoneRequest,
  registrationCreateUserRequest,
} from "requests";
import { selectError, selectIsFetching } from "selectors";
// import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import routes from "routes";
import { whatsappHrefBuilder, isValidEmail } from "utils";
import { ReferenceModal } from "./ReferenceModal";
import {
  FlexRow,
  CustomLink,
  FormContainer,
  WhatsAppHelp,
} from "../components";
import { INVALID_PHONE_ERROR, INVALID_EMAIL_ERROR } from "../Login";
import { useFormInputs, formKeys } from "./useFormInputs";
import { theme } from "theme";

export const PASSWORD_MIN_LENGTH = {
  length: 8,
  message: "La contraseña debe tener al menos 8 caracteres",
};
export const PASSWORD_DONT_MATCH = "Las contraseñas no coinciden";
const INCORRECT_CODE = "Código incorrecto";

export const Register = () => {
  const dispatch = useDispatch();
  const country = useCountry();
  const [hasSubmittedPhoneNumber, setHasSubmittedPhoneNumber] = useState(false);
  const [hasSubmittedCode, setHasSubmittedCode] = useState(false);
  const [hasSubmittedCredentials, setHasSubmittedCredentials] = useState(false);

  const isFetchingRegistrationStart = useSelector((state) =>
    selectIsFetching(state, { request: registrationStartRequest }),
  );
  const isFetchingVerifyPhoneNumber = useSelector((state) =>
    selectIsFetching(state, { request: registrationVerifyPhoneRequest }),
  );
  const isFetchingCreateUser = useSelector((state) =>
    selectIsFetching(state, { request: registrationCreateUserRequest }),
  );
  const createUserError = useSelector((state) =>
    selectError(state, { request: registrationCreateUserRequest }),
  );

  const formInputs = useFormInputs();
  const form = useForm(formInputs);
  const { getValuesObj, updateValues } = form;

  const valuesObj = getValuesObj();
  const phoneNumber = valuesObj[formKeys.phoneNumber];

  const submitPhoneNumber = async () => {
    const errors = [];

    if (!valuesObj[formKeys.smsConsent]) {
      errors.push({
        key: formKeys.smsConsent,
        error: "Debes aceptar recibir notificaciones",
      });
    }

    const isValidPhoneNumber = (await import("libphonenumber-js/mobile"))
      .isValidPhoneNumber;

    if (!phoneNumber) {
      errors.push({ key: formKeys.phoneNumber, error: REQUIRED_FIELD_ERROR });
    } else if (!isValidPhoneNumber(phoneNumber, country.code)) {
      errors.push({ key: formKeys.phoneNumber, error: INVALID_PHONE_ERROR });
    }

    if (!isEmpty(errors)) {
      updateValues(errors);
      return;
    }

    dispatch(
      registrationStartRequest(
        { phoneNumber, country: country.code },
        {
          onResponse: {
            successCallback: () => {
              setHasSubmittedPhoneNumber(true);
              updateValues([
                { key: formKeys.phoneNumber, disabled: true },
                { key: formKeys.smsConsent, disabled: true },
              ]);
            },
            errorCallback: (error) => {
              handleNewPhoneNumberError({
                errorCode: error.code,
                formKey: formKeys.phoneNumber,
                updateValues,
              });
            },
          },
        },
      ),
    );
  };

  const submitCode = () => {
    const errors = [];

    const code = valuesObj[formKeys.code];
    if (!code) {
      errors.push({ key: formKeys.code, error: REQUIRED_FIELD_ERROR });
    } else if (code.length < 4) {
      updateValues([{ key: formKeys.code, error: INCORRECT_CODE }]);
    }

    if (!isEmpty(errors)) {
      updateValues(errors);
      return;
    }

    dispatch(
      registrationVerifyPhoneRequest(
        { code, phoneNumber, country: country.code },
        {
          onResponse: {
            successCallback: () => {
              setHasSubmittedCode(true);
            },
            errorCallback: () => {
              updateValues([{ key: formKeys.code, error: INCORRECT_CODE }]);
            },
          },
        },
      ),
    );
  };

  const submitCredentials = () => {
    const errors = [];

    const email = valuesObj[formKeys.email];
    if (!email) {
      errors.push({ key: formKeys.email, error: REQUIRED_FIELD_ERROR });
    } else if (!isValidEmail(email)) {
      errors.push({ key: formKeys.email, error: INVALID_EMAIL_ERROR });
    }

    const password = valuesObj[formKeys.password];
    if (!password) {
      errors.push({ key: formKeys.password, error: REQUIRED_FIELD_ERROR });
    } else if (size(password) < PASSWORD_MIN_LENGTH.length) {
      errors.push({
        key: formKeys.password,
        error: PASSWORD_MIN_LENGTH.message,
      });
    } else if (password !== valuesObj[formKeys.repeatPassword]) {
      errors.push({
        key: formKeys.repeatPassword,
        error: PASSWORD_DONT_MATCH,
      });
    }

    if (!isEmpty(errors)) {
      updateValues(errors);
      return;
    }

    const dispatchCreateUser = (referencedBy?: string) => {
      dispatch(
        registrationCreateUserRequest(
          {
            email,
            password,
            country: country.code,
            referencedBy: referencedBy ? referencedBy : undefined,
          },
          {
            onResponse: {
              successCallback: () => {
                setHasSubmittedCredentials(true);
                updateValues([{ key: formKeys.email, disabled: true }]);
              },
              errorCallback: (error) => {
                if (error.code === "email-already-registered") {
                  updateValues([
                    {
                      key: formKeys.email,
                      error: "Este email ya se encuentra registrado",
                    },
                  ]);
                }
                dispatch(openSnackbar({ variant: "error", message: "Error" }));
              },
            },
          },
        ),
      );
    };

    dispatch(
      openModal({
        node: (
          <ReferenceModal
            dispatchCreateUser={dispatchCreateUser}
            userEmail={email}
          />
        ),
      }),
    );
  };

  const changePhoneNumber = () => {
    setHasSubmittedPhoneNumber(false);
    updateValues([
      { key: formKeys.phoneNumber, value: "", disabled: false },
      { key: formKeys.smsConsent, value: false, disabled: false },
    ]);
  };

  const renderForm = () => {
    if (!hasSubmittedPhoneNumber) {
      return (
        <ButtonContainer>
          <Button
            loading={isFetchingRegistrationStart}
            onClick={submitPhoneNumber}
            color="primary"
          >
            Continuar
          </Button>
        </ButtonContainer>
      );
    }

    if (!hasSubmittedCode) {
      return (
        <>
          <CodeDescription>
            Mandamos un mensaje al celular con un código. Puede tardar hasta 5
            minutos en llegar.
            <br />
            <br />
            Ingresa el código:
          </CodeDescription>
          <TextField form={form} formKey={formKeys.code} />

          <ButtonContainer>
            <Button
              onClick={submitCode}
              loading={isFetchingVerifyPhoneNumber}
              color="primary"
            >
              Continuar
            </Button>
          </ButtonContainer>
        </>
      );
    }

    if (hasSubmittedCode) {
      return (
        <>
          <Spacer height={20} />
          <TextField form={form} formKey={formKeys.email} />
          <Spacer height={20} />
          {!hasSubmittedCredentials ? (
            <>
              <TextField form={form} formKey={formKeys.password} />
              <Spacer height={20} />
              <TextField form={form} formKey={formKeys.repeatPassword} />
              {createUserError && (
                <InputError>
                  Hubo un error. Asegúrate que tu email es correcto.
                </InputError>
              )}
              <Spacer height={20} />
              <ButtonContainer>
                <Button
                  onClick={submitCredentials}
                  loading={isFetchingCreateUser}
                  color="primary"
                >
                  Continuar
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <RegistrationComplete>
              <ColoredIcon color={theme.palette.success.main}>
                <CheckCircleIcon />
              </ColoredIcon>
              <h3>
                ¡Registro exitoso! Te mandamos un email con un link para que
                actives tu cuenta. Busca también en tu bandeja de{" "}
                <u>correo no deseado.</u>
              </h3>
            </RegistrationComplete>
          )}
        </>
      );
    }

    return null;
  };

  return (
    <FormContainer>
      <h1>Crea una cuenta gratis</h1>
      <p>Necesitas una cuenta para poder publicar anuncios.</p>
      <p>Ingresa los datos para crear una cuenta</p>

      <TextField form={form} formKey={formKeys.phoneNumber} />
      {hasSubmittedPhoneNumber && !hasSubmittedCode && (
        <ChangePhoneNumber onClick={changePhoneNumber}>
          Cambiar teléfono
        </ChangePhoneNumber>
      )}
      {!hasSubmittedPhoneNumber && (
        <>
          <Spacer height={20} />
          <CheckboxesContainer>
            <Checkbox form={form} formKey={formKeys.smsConsent} />
          </CheckboxesContainer>
        </>
      )}

      {renderForm()}

      {!hasSubmittedCredentials && (
        <>
          <WhatsAppHelp>
            ¿Necesitas ayuda? Contáctanos por{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={whatsappHrefBuilder({
                message: "Hola, necesito ayuda con mi registro.",
                phoneCountryCode: "52",
                phoneNumber: NEXT_PUBLIC_ADMIN_WHATSAPP,
              })}
            >
              WhatsApp en este link.
            </a>
          </WhatsAppHelp>
          <FlexRow>
            ¿Ya tienes cuenta?{" "}
            <Link href={routes.login()} passHref>
              <CustomLink>Inicia sesión</CustomLink>
            </Link>
          </FlexRow>

          <Spacer height={20} />
          <Link href={routes.olvideContrasena()} passHref>
            <CustomLink>¿Olividaste tu contraseña?</CustomLink>
          </Link>
        </>
      )}
    </FormContainer>
  );
};

const ButtonContainer = styled.div`
  margin: 10px 0;
`;
export const CodeDescription = styled.p`
  width: 400px;
  margin-bottom: 0;
  max-width: 90%;
  text-align: center;
`;
const ChangePhoneNumber = styled.p`
  color: ${theme.palette.blue.main};
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0;
`;
const CheckboxesContainer = styled.div`
  .MuiFormControlLabel-label {
    width: 250px;
  }
`;
const RegistrationComplete = styled.div`
  margin-top: 20px;
  width: 400px;
`;
const ColoredIcon = styled.div<{ color: string }>`
  svg {
    height: 70px;
    width: 70px;
    color: ${({ color }) => color};
  }
`;
